@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
.MomentsViewContainer{
    padding: 20px;
    .moments-list{
        width: 100%;
        max-width: 500px;
        margin: 0;
        border-top: 1px solid #ccc;
        padding-top: 20px;
        @include laptopUp{
            max-width: calc(100% - 460px);
            border: 1px solid #ccc;
            padding: 20px;
        }
    }
}
.MomentListContainer{
    max-width: 500px;
    margin: 0 auto;
    // @include laptopUp{
        // height: calc(100vh - 236px);
        // overflow: auto;
    // }
}
.checkboxColored{
    .MuiFormControlLabel-label{
        transition: color 300ms ease;
    }
    svg path{
        transition: fill 300ms ease;
    }
    &:hover,
    &.checked{
        color: #00AB55;
        .MuiFormControlLabel-label{
            font-weight: 600;
        }
        svg path{
            fill: #00AB55;
        }
    }
}


