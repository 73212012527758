@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}

.moment-list-wrapper{
    background-color: #f7f7f7;
    border-radius: 4px;
    overflow: auto;
    padding-right: 10px;
}

.momentItem{
    position: relative;
    background-color: #fff;
    padding: 10px;
    max-width: 500px;
    border-radius: 8px;
    p{
        margin-top: 0;
        font-size: 0.9rem;
        color: #637381;
        word-break: break-word;
    }
    // .date-wrapper{
    //     @include tabletUp{
    //         display: none;
    //     }
    // }

    .moment-more-option-trigger{
        position: absolute;
        top: 4px;
        right: 4px;
    }
}
.date-wrapper{
    display: flex;
    margin-top: 10px;
    &.left-side{
        flex-direction: column;
    }
    p{
        font-size: 12px;
        color: #a3a3a3;
        // @include tabletUp{
        //     font-size: 14px;
        // }
        margin-right: 15px;
    }
}

.momentItem{
    .momentItem-desc{
        text-align: left;
        &.clickable{
            cursor: pointer;
            transition: all 300ms ease;
            &:hover{
                color: #00AB55;
            }
        }
    }
    .feeling-emotion{
        margin-top: 8px;
    }
}
.section-title-04{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    // color: #637381;
    margin-bottom: 16px;
    
    color: #00AB55;
    display: inline-block;
    border-radius: 4px;
    padding: 0 7px;
    background-color: #ebf8f2;
}
.moment-type{
    background-color: rgba(0, 171, 85, 0.08);
    color: #00AB55;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0.5px;
    display: inline-block;
    margin-bottom: 4px;
}