@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}

.timeline-wrapper{
    position: relative;
    overflow: hidden;
    padding-top: 37.037%;
    max-width: 1600px;
    margin: 0 auto;
    background-color: #dbdbdb;
}
.timeline-img-container{
    position: absolute;
    top: 0;
    width: 100%;
}
.page-image-container{
    height: 120px;
    flex: 0 0 120px;
    width: 120px;
    overflow: hidden;
    border: 4px solid #fff;
    border-radius: 50%;
    position: relative;
    margin-top: -60px;
    background-color: #fff;

    box-shadow: 1px 2px 5px 0px rgba(165,165,165,0.75);
    -webkit-box-shadow: 1px 2px 5px 0px rgba(165,165,165,0.75);
    -moz-box-shadow: 1px 2px 5px 0px rgba(165,165,165,0.75);
    @include laptopUp{
        height: 200px;
        flex: 0 0 200px;
        width: 200px;
        margin-top: -100px;
    }
    @include mobileUp {
        height: 150px;
        flex: 0 0 150px;
        width: 150px;
        margin-top: -75px;
        // border: 8px solid #fff;
    }
    
    img{
        position: absolute;

    }
}
.pp-wrap-inner{
    width: 100%;
    max-width: 1180px;
    padding: 0 20px;
    margin: 0 auto;
    padding: 0 20px;
    @include tabletUp {
        padding: 0 40px;
    }
}
.page-and-image-and-name-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 8px;
    @include laptopUp{
        flex-direction: row;
    }
    @include tabletUp {
        padding: 0 40px;
    }
    @include mobileUp {
        align-items: flex-start;
        text-align: left;
        padding: 0 20px;
    }
    .buttonActions{
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        position: static;
        right: 37px;
        justify-content: center;
        gap: 8px;
        @include laptopUp{
            position: static;
        }
        @include mobileUp{
            position: absolute;
            // flex-direction: column;
            margin-left: auto;
        }
    }
    .membersTrigger{
        color:#007B55;
    }
}
.joinRequest{
    color:#00ab55;
    dd{
        color:#00ab55;
    }
}
.name-and-description{
    padding: 10px 14px;
    width: 100%;
    max-width: 700px;
    // @include mobileUp {
    //     width: calc(100% - 200px);
    // }
    .clickable{
        color:#00ab55;
        cursor: pointer;
        dd{
            color:#00ab55;
        }
    }
    .info{
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        svg{
            width: 18px;
            margin-right: 4px;
        }
    }
}
.page-name{
    color:#007B55;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 8px;

    @include mobileUp {
        font-size: 28px;
    }
}
.page-content-wrapper{
    margin: 0 auto;
    width: 100%;
    max-width: 1010px; 
    padding: 0 20px;
    border-top: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    @include mobileUp {
        padding: 0 40px;
    }
}
// .ideas-and-purpose-wrapper{
// }
.idea{
    text-align: center;
    font-size: 24px;
    line-height: 1;
    color: #222;
    margin-bottom: 18px;
    @include mobileUp {
        font-size: 28px;
    }
}
.purpose{
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #767676;
    width: 80%;
    max-width: 409px;
    margin: 0 auto;
    @include mobileUp {
        font-size: 18px;
    }
}
.optionsContainerAndinfo{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    @include mobileUp {
        // padding: 20px;
    }
    @include tabletUp {
        flex-direction: row;
    }
}
.follow-actions{
   display: flex;
   align-items: center;
   li{
    margin-right: 16px;
    &:last-child{
        margin-right: 0;
    }
    @include mobileUp {
        font-size: 16px;
    }
    a{
        color: #656565;
        font-size: 14px;
        transition: color 300ms ease;
        text-decoration: none;
        &:hover{
            color: #00AB55;
        }
    }
   }
}
.postList{
    width: 100%;
    // max-width: 550px;
    // margin: 0 auto;
}
.PostUploadTrigger{
    display: flex;
    align-items: center;
    padding: 0 20px;
    max-width: 550px;
    margin: 0 auto 17px;
    @include mobileUp {
        padding: 0;
    }
}
.postTriggerText{
    font-size: 18px;
    color: #222;
    border-radius: 20px;
    width: 100%;
    background-color: #efefef;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    flex-grow: 1;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    cursor: pointer;
    transition: all 300ms ease;
    &:hover{
        background-color: #F4F6F8;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
}
.postPageInfoAreaSection{
    margin-bottom: 32px;
}

.postPageInfoArea{
    font-size: 16px;
    width: 100%;
    max-width: 210px;
    margin-bottom: 28px;

    @include mobileUp {
        margin-right: 24px;
        padding-right: 16px;
        border-right: 1px solid #e0e0e0;
    }
    dt{
        width: 107px;
    }
    dl{
        margin-bottom: 9px;
    }
    dd{
        color: #222;
    }
    
}
.page-description{
    margin-bottom: 6px;
}
.student-name-and-role{
    margin-bottom: 8px;
    .page-name{
        margin-bottom: 0;
    }
}
.role{
    font-size: 14px;
}