// .GroupListContainer{
//   padding: 16px 20px;
//   width: 300px;
//   height: calc(100vh - 45px);
//   border-right: 1px solid #E7E7E7;
//   flex: calc(25% - 15px) 0;
//   background-color: #fff;
//   height: calc(100vh - 45px);
//   position: fixed;
//   z-index: 4;
//   background: #fff;
//   transition: all 300ms ease;
//   height: 100vh;
//   left: -82px;
//   top: 0;
//   &.show{
//     left: 220px;
//   }
// }

// .GroupListItemContainer{
//   display: flex;
//   align-items: flex-start;
//   padding: 13px 0;
//   border-bottom: 1px solid #e7e7e7;
// }
.notificationHeadingImage{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 34px;
  width: 34px;
  height: 34px;
  border: #E7E7E7;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 13px;
  text-transform: uppercase;
}
// .listItemDetailsContainer{
//   .itemName{
//     color: #212B36;
//     cursor: pointer;
//     font-size: 14px;
//     word-break: break-word;
//     margin-bottom: 2px;
//     font-family: "Public Sans", sans-serif;
//     font-weight: 600;
//     text-decoration: none;
//     transition: all 300ms ease;
//     &:hover{
//       color: #00ab55;
//     }
//   }
//   .email{
//     color: #637381;
//     font-size: 14px;
//     font-family: "Public Sans", sans-serif;
//   }
//   button{
//     font-size: 10px;
//   }
// }
// .members{
//   font-size: 14px;
// }