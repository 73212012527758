@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
.TaskViewContainer{
    position: relative;
    display: flex;
}
.asignee-info{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 0.9rem;
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    .label{
        margin-right: 10px;
    }
    .MuiSelect-select{
        display: flex;
    }
    .icon{
        max-width: 20px;
        margin-right: 5px;
        img{
            border-radius: 20px;
        }
    }
}

.actions-container{
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    @include tabletUp {
        margin: 0 10px 0 0;
    }
    li{
        display: flex;
        align-items: center;
        padding: 0 20px 0 0;
        margin-left: 0;
        transition: all 300ms ease;
        cursor: pointer;
        border-radius: 4px;
        @include tabletUp {
            margin-left: 20px;
            padding: 0 10px;
        }
        &:hover{
            background: rgba(0, 171, 85, 0.08);
            .text{
                color: #00AB55;
            }
            svg path{
                fill: #00AB55;
            }
        }
        .text,
        svg path{
            transition: all 300ms ease;
        }
    }
    .icon{
        width: 20px;
        margin-right: 10px;
        position: relative;
        &>svg{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}