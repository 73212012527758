.BigBroSuggestionContainer{
    padding: 20px 0;
    display: flex;
    .chatbox-container{
        background: rgba(145, 158, 171, 0.08);
        border-radius: 8px;
        padding: 0;
        max-width: 600px;
        margin: 0 auto;
        height: calc(100vh - 40px);
        overflow: auto;
        border: 2px solid #ececec;
        display: flex;
        align-items: stretch;
        width: 50%;
    }
    .conversation-container{
        height: calc(100vh - 165px);
    }
}
.conversation-container{
    overflow: auto;
    padding: 14px 20px;
    .chat-dialog{
        &:last-child{
            margin-bottom: 5px;
        }
    }
}
.suggestion-list-container{
    width: 50%;
}
.suggestion-container{
    margin-bottom: 14px;
    max-width: 300px;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #dadada;
}
.yt-suggestion-title{
    font-size: 16px;
    font-weight: 700;
    color: #222;
    padding: 5px 8px;
}
.yt-suggestion-description{
    color: #373737;
    padding: 0 8px 5px 8px;
}

.subject-list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 5px 5px;
    margin: 0 auto;
    width: 150px;
}
.training-inquiry,
.training-answer,
.source-link-list{
    margin-bottom: 10px;
}
.source-link-list{
    padding: 0 5px 5px;
    width: 200px;
    overflow: hidden;
    word-break: break-word;
    margin: 0 auto;
}
.intent-tag,
.subject-tag{
    word-break: break-word;
}
.eclair{
    position: relative;
    padding: 4px 10px;
    border-radius: 12px;
    background-color: #ccc;
    margin: 0 5px 10px;
    &:hover{
        .remove-trigger{
            opacity: 1;
        }
    }
    .remove-trigger{
        opacity: 0;
        transition: all 300ms ease;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%,-50%);
        z-index: 2;
        background-color: rgba(170, 170, 170,0.5);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
    }
}