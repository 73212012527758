.emotionsListWrapper{
    position: relative;
    .close{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 6px;
        right: 0;
        border-radius: 50%;
        background-color: #ebebeb;
        padding: 4px;
        cursor: pointer;
        transition: all 200ms ease;
        &:hover{
            background-color: #b2b2b2;
        }
    }
}
.emotionListSelection{
    text-align: center;
}