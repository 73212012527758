@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}
.PostCardsContainer{
    padding: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    margin-bottom: 20px;
    @include mobileUp {
        border-radius: 8px;
    }
    &.minified{
        width: 100%;
        max-width: 330px;
        padding: 12px 12px 0;
        margin-bottom: 0;
        .commentAndShare,
        .postReactionsWrapper{
            font-size: 14px;
        }
        .cardImageWrapper{
            margin: 0 -12px;
            .cardContent{
                color: #fff;
                font-weight: 300;
                font-size: 14px;
            }
        }
        .postTextContentWrapper{
            position: relative;
            background: rgb(0,0,0);
            background: linear-gradient(0deg, rgba(0,0,0,0.5999649859943977) 80%, rgba(0,0,0,0.3534663865546218) 90%, rgba(0,0,0,0) 100%);
            width: 100%;
            padding: 4px 8px 13px 8px;
            bottom: 0;
            margin-top: -37px;
            margin-bottom: 0;
            transition: all 300ms ease;
            &.active{
                margin-top: 0;
                background: rgb(0,0,0,0.8);
            }
            .cardContent{
                margin-bottom: 0;
                max-height: 20px;
                overflow: hidden;
                &.showMorePostText{
                    max-height: none;
                }
            }
        }
        .showMore{
            position: absolute;
            bottom: 0;
            color: #fff;
            right: 8px;
            font-size: 10px;
            cursor: pointer;
        }
    }
}
.postTextContentWrapper{
    padding: 0 20px;
}
.postPointsInputWrapper{
    display: flex;
    align-items: center;
}
.cardImageWrapper{
    position: relative;
    margin: 0 -20px;
}
.load-more-comments{
    text-align: center;
    cursor: pointer;
    color: #00AB55;
    font-size: 12px;
    font-weight: 600;
    margin: 0 auto 12px;
    background-color: #fff;
    transition: all 300ms ease;
    &:hover{
        background-color: rgba(0, 171, 85, 0.08);
    }
}
.cardHeader{
    display: flex;
    align-items: flex-start;
    margin-bottom: 17px;
    .name{
        font-size: 15px;
        line-height: 1.2;
        color: #454F5B;
        margin-bottom: 4px;
    }
    .date{
        font-size: 12px;
        color: #9b9b9b;
    }
}
.post-feeling{
    font-weight: 400;
    color: #a0a0a0;
}
.userPostImage{
    position: relative;
    display: flex;
    margin-right: 18px;
    flex: 0 0 40px;
    height: 40px;
    a{
        display: block;
        overflow: hidden;
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }
    &.noPicture{
        border: 2px solid #b5bcc4;
        border-radius: 100%;
    }
    img{
        // position: absolute;
        width: 100%;
        height: auto;
        border-radius: 40px;
    }
}
.postOtherInfo{
    display: flex;
    align-items: center;
}
.followText{
    display: flex;
    font-size: 12px;
    font-weight: 600;
    margin-left: 4px;
    color: #9b9b9b;
    position: relative;
    padding-left: 8px;
    transition: all 300ms ease;
    cursor: pointer;
    &:before{
        content: "";
        background-color: #9b9b9b;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 300ms ease;
    }
    &:hover,
    &.following{
        color: #00AB55;
        &:before{
            background-color: #00AB55;
        }
    }
}
.energyWrapper{
    display: flex;
    align-items: center;
    .ratingWrapper{
        margin-bottom: -6px;
    }
}
.energyLabel{
    margin-right: 5px;
}
.cardContent{
    margin-bottom: 10px;
    font-weight: 600;
    color: #212121;
    font-size: 15px;
}
.cardContentWrapper{

}
.cardReactionsInfo{
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.cardReactionsActions{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 20px;
    li{
        cursor: pointer;
        width: 33.3333%;
        align-items: center;
        padding: 10px;
        text-align: center;
        font-weight: 600;
    }
}
.postActions{
    flex: 1;
}
.commentAndShare{
    margin-left: auto;
}
.commentListWrapper{
    max-height: 400px;
    overflow: auto;
}

.postPointsSliderWrapper{
    display: flex;
    width: 100%;
    max-width: 200px;
    padding: 0 10px;
    margin-left: 16px;
}