.UserDisabledOrNotExistWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.UserDisabledOrNotExistContent{
  text-align: center;
  h1{
    font-size: 32px;
  }
  p{
    font-size: 16px;
    margin-bottom: 16px;
  }
  a{
    font-size: 16px;
    width: 250px;
    margin: 0 auto;
  }
}