.ReactionPopupContainer{
    position: relative;
    &:hover{
        cursor: pointer;
        &:before{
            content: "";
            width: 100%;
            height: 20px;
            display: block;
            position: absolute;
            top: -17px;
        }
        .reactionPopup {
            height: 55px;
        }
    }
}

.postReactionsWrapper{
    display: flex;
    margin-right: auto;
    position: relative;
}

.postReactions{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #f4f6f8;
    border-radius: 24px;
    margin-left: -4px;
}

.postReactionsClickable{
    font-size: 24px;
    padding: 4px;
    &:hover{
        font-size: 34px;
    }
}

.reactionPopup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    width: 260px;
    height: 0;
    overflow: hidden;
    transition: height 0.1s;
    background: #fff;
    bottom: 41px;
    border-radius: 27px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    left: 0;
    // left: 50%;
    // transform: translateX(-50%);
}