.tag-list{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .reflect-tag{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 7px;
        height: 22px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 12px;
        background: rgba(99, 115, 129, 0.16);
        letter-spacing: 0.25px;
        color: rgb(99, 115, 129);
        margin: 0 5px 5px 0;
        cursor: pointer;
        transition: all 300ms ease;
        &.selected{
            color: rgb(0, 171, 85);
            background: rgba(0, 171, 85, 0.08);
        }
    }
}
.reflect-selected-tag{
    color: rgb(0, 171, 85);
    margin: 0 6px 6px 0;

}

.tag-selection-heading{
    margin-bottom: 10px;
    font-weight: 600;
}