.CheckoutContainer{
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}
.PaymentContainer{
    padding-bottom: 6px;
  h1{
    padding-bottom: 40px;
  }
}
#payment-element{
  margin-bottom: 20px;
}