.BigBroTrainingContainer{
    padding: 20px;
}

.filter-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.action-button-container{
    width: 80px;
    margin: 0 auto;
    button{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.training-table{
    td{
        position: relative;
        height: 100%;
    }
}

.cell-input-container{
    position: relative;
}

textarea{
    border:0;
}
.subject-form-wrapper{
    .action-wrapper{
        margin-top: 10px;
    }
}
.answer-wrapper{
    position: relative;
    padding: 0 0 70px;
    text-align-last: left;
    // .suggestion-priority-selection{
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    // }
}
.suggestion-priority-selection{
    .radio-button-group{
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .MuiFormControlLabel-label{
        font-size: 14px;
    }
}
.training-options-and-table{
    display: flex;
}
.training-table-wrapper{
    padding-left: 20px;
    flex: 0 0 calc(100% - 280px);
}
.answer-wrapper{
    .rating-container{
        cursor: pointer;
    }
}
.rating-info{
    color: #fbaf02;
    font-weight: 700;
}