.TimeProgressContainer{
    padding: 6px 0;
    font-size: 14px;
}
.progress-bar-wrapper{
    padding: 6px 0;
}
.time-rendered{
    display: flex;
    align-items: center;
    justify-content: space-between;
}