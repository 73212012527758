@mixin wideUp {
    @media (min-width: 1730px) {
        @content;
    }
}
@mixin desktopUp {
    @media (min-width: 1300px) {
        @content;
    }
}

@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}
.TaskEstimatesPlanContainer{
    padding: 20px 20px 100px;
    width: 100%;
    max-width: 550px;
    position: fixed;
    background-color: #fff;
    top: 46px;
    right: -580px;
    height: calc(100vh - 46px);
    overflow: auto;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: all 300ms ease;
    z-index: 1;
    @include tabletUp {
        top: 0;
        height: 100vh;
        // right: 0;
    }
    @include desktopUp{
        box-shadow: none;
        position: static;
        max-width: 100%;
        flex: 500px 1 1;
    }
    @include wideUp{
        flex: 700px 1 1;
    }
    &.open{
        right: 0;
    }
}
.estimate-row-data{
    .estimate{
        font-size: 16px;
        width: 70px;
        padding: 5px 8px;
        border: 1px solid #ccc;
    }
}
.work-time-data{
    margin-bottom: 20px;
    @include mobileUp{
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
}
.time-schedule,
.work-availability{
    @include mobileUp{
        width: calc(50% - 15px);
    }
}
.val{
    font-weight: 700;
}
.planning-action-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button{
        margin-left: 14px;
        &:first-child{
            margin-left: 0;
        }
    }
}
.user-time-schedule{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.planning-input-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
    .MemberSelectionContainer{
        margin: -8px 0 16px;
    }
    .add-task-button{
        margin: 20px 0 0 auto;
    }
}
.planning-input-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .section-heading{
        margin-bottom: 0;
    }
}
.hideOnDesktop{
    @include desktopUp{
        display: none!important;
    }
}