.login-form{
    max-width: 350px;
    margin: 0 auto;
}
.registration-link{
    color: #1976d2;
    text-decoration: none;
}
.forgot-password-link,
.registration-link{
    color: #00AB55;
    cursor: pointer;
}
.forgot-password-and-login-options{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
