.PostUploadContainer{
    padding: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%), 0px 12px 24px -4px rgb(145 158 171 / 12%);
    margin-bottom: 20px;
    .cardHeader{
        display: flex;
    }
}
.uploadWrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 300px;
    border: 2px dashed #454F5B;
    background: #F4F6F8;
    overflow: hidden;
    &.cursorOnFrame{
        border-color: #01ab56;
        background-color: rgba(0, 171, 85, 0.08);
    }
}
.fileDropUpload{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(255,255,255,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploadWrapper{
    margin: 16px 0;
}
.uploadImageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 400px;
    .uploadImageEditButton{
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100;
        background: rgb(255 255 255 / 70%);
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        &:hover{
            background: rgb(255 255 255 / 100%);
        }
    }
}
.mood-container{
    .emotions-list{
        justify-content: center;
        width: 100%;
        max-width: 213px;
        margin: 0 auto;
    }
}

.moodLabel{
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    color: #a0a0a0;
}
.emotionOption{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #fff;
    width: 22px;
    height: 22px;
    margin-left: -5px;
    &:first-child{
        margin-left: 2px;
    }
}
.postImageContainer{
    position: relative;
    .uploadImageEditButton {
        position: absolute;
    }
}