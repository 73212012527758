.TodoCategoryListContainer{
  position: absolute;
  top: 61px;
  background: #fff;
  z-index: 5;
  width: 240px;
  height: calc(100vh - 60px);
  padding: 8px 10px 20px 0;
  border-right: 1px solid #E7E7E7;
  transition: all 300ms ease;
  left: -240px;
  &.open{
    left: 20px;
  }
  .tasks-filter-wrapper{
    padding-left: 0;
    margin-bottom: 12px;
    max-width: none;
  }
  .filter-checkbox-wrapper{
    display: flex;
    align-items: center;
  }
  .actions-wrapper{
    display: flex;
    margin-left: auto;
  }
}

.noteCategoryListHeading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .filter-by-heading{
    padding-top: 0;
    margin-bottom: 0;
  }
}