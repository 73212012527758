.GroupCardContainer{
  width: 100%;
  max-width: 360px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}
.classRoomTitle{
  color: #222;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 4px;
}
.classRoomDescription{
  color: #6F6F6F;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}
.classInfoContainer{
  padding: 10px 12px;
  .nav-link{
    height: auto;
  }
  
}
.classTimelinePicture{
  background-color: #E8E8E8;
  background-size: cover;
  background-position: center;
  height: 190px;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .nav-link{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0;
    height: auto;
    margin-bottom: 0;
  }
}
.classProfilePicture{
  width: 38px;
  height: 38px;
  border-radius: 100%;
  border: 3px solid #fff;
  position: absolute;
  left: 8px;
  bottom: 8px;
  overflow: hidden;
}