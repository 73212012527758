@mixin wideUp {
    @media (min-width: 1730px) {
        @content;
    }
}
@mixin desktopUp {
    @media (min-width: 1300px) {
        @content;
    }
}

@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}
a{
    text-decoration: none;
}
.calendar-and-todo-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // padding: 20px;
    // background-color: rgb(240, 240, 240);
}

.route-wrapper{
	flex: 100% 0 0;
    margin: 45px 0 0 auto;
    transition: flex 300ms ease;
    @include tabletUp{
        margin: 0 0 0 auto;
        flex: calc(100% - 220px) 0 0;
    }
}
p{
    word-break: break-word;
}
a{
    color: #00ab55;
}
.minimizedMenu{
    @include tabletUp{
        .route-wrapper{
            flex: calc(100% - 62px) 0 0;
        }
        .NavigationContainer{
            width: 62px;
            padding-left: 10px;
            padding-right: 10px;
        }
        .nav-logo-container{
            flex-direction: column;
            p{
                font-size: 0;
            }
        }
        .icon-container{
            margin-right: 0;
            display: flex;
            justify-content: center;
        }
        .nav-section-header{
            text-align: center;
        }
        .trigger,
        .menu-nav-name{
            display: none;
        }
        .todo-list-wrapper{
            left: 62px;
            &.hide{
                left: -238px
            }
        }
    }
    &.SideNavContainer{
        @include tabletUp{
            &.expand{
                max-width: calc(100% - 62px);
            }
        }
    }
}
.calendar-wrapper{
    flex: 100% 0;
    margin-left: auto;
    // height: calc(100vh - 40px);
    background-color: #fff;
    padding: 20px;
    transition: all 300ms ease;
    // @include laptopUp{
    //     flex: calc(100% - 300px) 0;
    // }
}
.showTodoList{
    .calendar-wrapper{
        @include laptopUp{
            flex: calc(100% - 300px) 0;
        }
    }
}
.note-wrapper{
    position: relative;
}
body{
    font-family: "Public Sans", sans-serif;
}
.aside-data .panel-title{
    margin: 0;
}
.section-title-01,
.section-title-02{
    margin: 0 auto 0 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #00AB55;
    text-align: center;
    line-height: 1;
    @include tabletUp{
        text-align: left;
    }
}
.section-title-02{
    font-size: 16px;
}
.NotesAsideContainer{
    position: fixed;
    width: 100%;
    max-width: 700px;
    top: 0;
    right: -100%;
    transition: right 300ms ease;
    background-color: #fff;
    padding: 16px 20px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    height: calc(100vh - 46px);
    top: 46px;
    z-index: 4;
    overflow: hidden;
    transition: all 300ms ease;
    @include tabletUp{
        width: calc(100% - 220px);
        height: 100vh;
        top: 0;
        &.expand{
            max-width: calc(100% - 220px);
        }
    }
    &.open{
        right: 0;
        box-shadow: 0 0px 6px rgba(0,0,0,0.16), 0 0px 6px rgba(0,0,0,0.23);
    }
}
.page-header-container{
    margin-bottom: 20px;
    @include tabletUp{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.text-title{
    font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #637381;
    // margin-bottom: 16px;
}
.editPicture{
    background-color: #181818;
    padding: 20px;
    position: relative;
    .actionsPictureBtn{
        position: absolute;
        top: 20px;
        // : 20px;   
    }
    .savePictureBtn{
        background-color: #00AB55;
        &:hover{
            background-color: #00AB55;
        }
    }
    .editPictureBtn{
        background-color: rgb(255 255 255 / 50%);
        &:hover{
            background-color: rgb(255 255 255 / 80%);
        }
    }
    .imageData{
        width: 100%;
        height: auto;
        object-fit: contain;
        margin-top: 48px;
        margin-bottom: 16px;
        @include tabletUp{
            height: calc(100vh - 40px);
            width: calc(100% - 440px);
            margin-bottom: 0;
            margin-top: 0;
        }
    }
    .fileDropUpload{
        max-width: 600px;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .file-drop-target{
        p, input{
            color: #fff;
        }
    }
    .uploadWrapper{
        background-color: rgba(0, 0, 0, 0.7);
        height: calc(100vh - 118px);
        margin-top: 61px;
    }
    .uploadImageWrapper{
        height: auto;
        & > img{
            display: block;
            object-fit: contain;
            width: 100%;
            height: calc(100vh - 118px);
        }
    }
}
.bold-with-cursor{
    color: #212B36;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}
.semiBold{
    color: #212B36;
    font-weight: 600;
}
.votesInfo{
    margin-right: 4px;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
    transition: opacity 300ms ease;
}
.MuiModal-root{
        
    &.MuiModal-hidden{
        .gridItemLayout{
            opacity: 0;   
        }
    }
}
.viewOptionWrapper{
    display: flex;
    justify-content: flex-end;
}

// for followers container
.FollowersContainer{
    padding: 20px;
}
.profileCardImgContainer{
    width: 150px;
    height: 150px;
    border-radius: 75px;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 10px;
}
.suggestedPeopleList{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    a{
        text-decoration: none;
        display: block;
    }
}
.userCard{
    max-width: 200px;
    width: 100%;
    text-align: center;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
}
.suggestedPeopleName{
    font-weight: 600;
    font-size: 18px;
    color: #637381;
    text-decoration: none;
}
.followLayoutContainer{
    padding: 18px 0;
    border-bottom: 1px solid #e7e7e7;
}
.pageWrapper{
    padding: 20px;
}
.pb200{
    padding-bottom: 200px;
}
.wrapHeading{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .section-title-04{
        margin-bottom: 0;
    }
    .nav-section-header{
        margin-bottom: 0;
    }
}