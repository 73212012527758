.plan-list{
    padding: 10px 0;
    li{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #00AB55;
        padding: 10px;
        background-color: #edf8f2;
        border-radius: 8px;
        margin-bottom: 8px;
        cursor: pointer;
    }
}