@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
.noteReference{
    font-size: 12px;
    margin: 4px 0;
}
.categoryLabel{
    font-size: 12px;
    margin: 6px 0;
    .bold{
        font-weight: bold;
    }
}
.SideNavContainer{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    max-width: 450px;
    border: 1px solid #E7E7E7;
    transition: all 300ms ease;
    position: fixed;
    z-index: 1;
    height: 100%;
    top: 45px;
    right: -100%;
    height: calc(100vh - 45px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    @include tabletUp{
        top: 0;
        height: 100vh;
        &.expand{
            max-width: calc(100% - 220px);
        }
    }
    // @include laptopUp{
    //     max-width: 450px;
    // }
    &.open{
        right: 0;
    }
    h2{ 
        margin: 0 auto 0 0;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
        color: #00AB55;
    }
    .todo-title{
        color: #3c3c3c;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.5;
        margin: 0px 0px 26px;
    }
    .todo-goal{
        color: #637381;
        font-size: 13px;
        padding: 3px 0;
    }
    .todo-status{
        font-size: 0.85rem;
        text-transform: uppercase;
        font-weight: 700;
        padding: 8px 8px;
        border-radius: 4px;
        margin-bottom: 0;
        display: inline-block;
        margin-right: auto;
        &.ongoing{
            color: rgb(0, 171, 85);
            background-color: rgba(0, 171, 85, 0.08);
        }
    }
    .todo-detail{
        color: rgb(145, 158, 171);
        font-size: 0.9rem;
        margin: 0;
        padding: 2px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg{
            display: block;
        }
    }
    .todo-name{
        font-size: 16px;
        // margin-bottom: 8px;
    }
    .asignee-info{
        margin-top: 8px;
    }
    .section-title-04{
        color: #637381;
        padding: 0;
        background-color: #fff;
    }
}
.todo-info-wrapper{
    position: relative;
    padding-top: 13px;
    .TodoMoreOptionsContainer{
        position: absolute;
        z-index: 2;
        right: 0;
        top: 10px;
    }
}
.sidenavContentWrapper{
    position: relative;
    overflow: auto;
    height: calc(100vh - 310px);
    @include tabletUp {
        height: calc(100vh - 265px);
    }
    .points-label{
        margin: 4px 0;
    }
    .load-more-comments{
        margin: 12px 0;
    }
}
.side-nav-wrapper{
    padding: 20px;
    height: calc(100vh - 260px);
    flex-direction: column;
    @include tabletUp {
        height: calc(100vh - 198px);
    }
}
.moment-form-wrapper{
    border-top: 1px solid #ccc;
    margin-top: auto;
    padding: 14px 20px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
.moment-type-label,
.moment-form-title{
    margin: 0;
    font-size: 14px;
}
.moment-form-title{
    font-weight: 600;
    line-height: 22px;
    color: #393939;
}
.moment-type-label{
    font-weight: 600;
    margin-right: 4px;
    padding-bottom: 4px;
    color: #393939;
}
.todo-detail{
    color: rgb(145, 158, 171);
    font-size: 0.9rem;
    margin: 10px 0;
    display: flex;
    align-items: flex-start;
    transition: color 300ms ease;
    &.clickable{
        &:hover{
            cursor: pointer;
            color: rgb(0, 171, 85);
        }
    }
    .label{
        margin-right: 10px;
    }
    .icon{
        margin-right: 5px;
    }
    .PostCardsContainer{
        padding: 12px;
    }
}
.todo-info-section{
    margin: 10px 0 12px;
}
.history-list{
    padding: 2px 0;
}
// .history-and-moment-wrapper{
    // height: calc(100vh - 535px);
    // overflow: auto;
    .todo-description{
        margin: 0;
        // font-weight: 600;
        font-size: 14px;
        word-break: break-word;
        transition: all 300ms ease;
        &.one-line{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
// }
.todo-info-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tasks-post-actions{
    display: flex;
    button{
        margin-left: 10px;
        padding: 0 5px;
        line-height: 1.5;
        font-size: 12px;
    }
}
.todo-posts-wrapper{
    padding-right: 10px;
}
.cardListWrapper{
    padding-bottom: 10px;
    .PostCardsContainer{
        &:last-child{
            margin-bottom: 0;
        }
    }
}