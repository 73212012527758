.postListContainer{
  margin-bottom: 12px;
  max-width: 550px;
  margin: 0 auto;
  &.gridView{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    max-width: none;
    .PostCardsContainer{
      margin-bottom: 0;
    }
  }
  
}