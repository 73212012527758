@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
.ratings-wrapper-portal{
    // width: 500px;
    // height: 100vh;
    // background: #fff;
    // z-index: 111;
    position: fixed;
    top: 0;
    right: 0;
}

.TrainingDataReviewsContainer{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    max-width: 360px;
    border: 1px solid #E7E7E7;
    transition: all 300ms ease;
    position: fixed;
    z-index: 1;
    height: 100%;
    top: 45px;
    right: -100%;
    height: calc(100vh - 45px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 20px;   
    overflow: auto;
    @include tabletUp{
        top: 0;
        height: 100vh;
    }
    @include laptopUp{
        max-width: 450px;
    }
    &.open{
        right: 0;
    }
}

.rating-list{
    .rating-data{
        padding: 20px 0;
        border-bottom: 1px solid rgb(231, 231, 231);
    }
}
.rating-detail{
    font-weight: 600;
    color: #212121;
}
.training-data-label{
    color: #999;
}
.inquiqy-and-response-wrapper{
    margin-bottom: 7px;
}
.training-data-details-wrapper{
    padding: 20px 0;
    border-bottom: 1px solid rgb(231, 231, 231);
    margin-bottom: 20px;
    .training-data-label{
        font-weight: 600;
        color: #01ab56;
    }
    .rating-detail{
        margin-bottom: 5px;
    }
}
.avatar-and-label{
    display: flex;
    align-items: center;
    & > div {
        margin-bottom: 0;
    }
    .training-data-label{
        margin-right: 5px;
    }
}
.training-data-date-detail{
    color: rgb(145, 158, 171);
    font-size: 0.9rem;
    margin: 4px 0 0;
    display: flex;
    align-items: flex-start;
    transition: color 300ms ease;
    .icon{
        margin-right: 5px;
    }
    .label{
        margin-right: 10px;
    }
}