.CommentContainer{
    display: flex;
    margin-bottom: 2px;
}
.userCommentImage{
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    overflow: hidden;
    flex: 0 0 30px;
    margin-right: 12px;
    &.noPicture{
        border: 2px solid #b5bcc4;
    }
    img{
        position: absolute;
    }
}
.commentVotes{
    display: flex;
    align-items: center;
    margin-left: auto;
}
.commentMessageWrapper{
    background: #F4F6F8;
    border-radius: 8px;
    padding: 8px 12px;
}
.commentInfoContainer{
    flex-grow: 1;
}
.commentOptions{
    display: flex;
    align-items: center;
    padding: 2px 12px 0;
}
.commentOptionStyle{
    cursor: pointer;
    color: #00AB55;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
    transition: opacity 300ms ease;
    &:hover{
        opacity: 1;
    }
}
.nameAndDateContainer{
    margin-right: auto;
}
.commentNameAndDateContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
    margin-right: auto;
}
.commenterName{
    font-weight: bold;
    a{
        color: #637381;
    }
}
.commentDate{
    font-size: 12px;
    color: #919EAB;
}
.commentContent{
    
}
.upVote{
    cursor: pointer;
    color: #00AB55;
}
.downVote{
    cursor: pointer;
    color: "#d32f2f"
}
.EditCommentForm{
    display: flex;
}