.EmergentIndexHeadingWrapper{
  width: 100%;
  max-width: 350px;
  font-size: 14px;
  table{
    td+td{
      font-weight: 700;
    }
  }
}
.EmergentIndexHeading{
  display: flex;
  font-size: 18px;
  padding-right: 26px;
}
.tableWrapper{
  padding: 16px 26px 0 0;
}
.emergentIndexTable{
  width: 100%;
  tr{
    border-bottom: 1px solid #ccc;
    &:last-child{
      border-bottom: 0;
    }
  }
}