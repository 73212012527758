@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin tabletDown {
    @media (max-width: 768px) {
        @content;
    }
}
.NavigationContainer{
    padding: 16px 20px;
    width: 220px;
    height: calc(100vh - 45px);
    margin-top: 45px;
    box-shadow: 0 0px 6px rgba(0,0,0,0.16), 0 0px 6px rgba(0,0,0,0.23);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    overflow: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    transition: all 300ms ease;
    
    @include tabletUp{
        height: 100vh;
        margin-top: 0;
        left: 0;
    }
    @include tabletDown{
        left: -100%;
        &.open{
            left: 0;
        }
    }
    ul{
        list-style: none;
    }
}
.text-link{
    text-decoration: none;
    color: #00AB55;
}
.nav-link{
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #637381;
    text-decoration: none;
    padding: 0;
    height: 34px;
    margin-bottom: 4px;
    border-radius: 8px;
    transition: all 300ms ease;
    cursor: pointer;
    path{
        transition: all 300ms ease;
    }
    &.active,
    &:hover{
        background: rgba(0, 171, 85, 0.08);
        color: #00AB55;
        path{
            fill: #00AB55;
        }
    }
    &.group-nav{
        font-size: 12px;
        padding: 8px 10px;
    }
}
.icon-container{
    display: block;
    width: 30px;
    margin-right: 8px;
    &>svg{
        display: block;
        width: 100%;
    }
}
.nav-logo-container{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    p{
        margin-left: 5px;
        font-size: 18px;
    }
}
.nav-section-header,
.filter-by-heading{
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #212B36;
    margin-bottom: 12px;
    padding: 0;
}
.filter-by-heading{
    padding: 12px 0 0;
    margin-bottom: 6px;
    color: #5b5b5b;
}
.nav-section{
    margin-bottom: 28px;
    &.logout-wrapper{
        margin: auto 0 0;
    }
}
.slide-trigger-wrapper{
    position: relative;
    &.full-content-trigger{
        cursor: pointer;
    }
    .trigger{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        &.open{
            svg{
                transform: rotate(-180deg);
            }
        }
        svg{
            transition: all 300ms ease;
        }
    }
}
.SlideToggleContainer{
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    color: #637381;
    font-family: 'Public Sans', sans-serif;
}
.tasks-filter-wrapper{
    max-width: 175px;
    padding-left: 16px;
    label{
        span{
            font-size: 12px;
            display: flex;
        }
        .checkboxIconMoment{
            width: 18px;
            margin-right: 5px;
            margin-left: -2px;
        }
    }
    .MuiRadio-root,
    .MuiCheckbox-root{
        svg{
            width: 16px;
        }
    }
}












.MobileHeaderContainer{
    position: fixed;
    z-index: 200;
    height: 46px;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 0 17px;
    @include tabletUp{
        display: none;
    }
    .nav-logo-container{
        margin-bottom: 0;
        p{
            color: #00AB55;
            font-weight: 700;
            text-transform: uppercase;
            margin-left: 5px;
        }
    }
    .logo-wrapper{
        max-width: 20px;
        svg{
            width: 100%;
        }
    }

}



.one { width: 6.866%; }

/* ALL */

.hamburger{
    height: 33px;
    margin-left: auto;
    &:hover{
        cursor: pointer;
      }
    .line{
        width: 25px;
        height: 4px;
        background-color: #00AB55;
        display: block;
        margin: 4px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        border-radius: 4px;
    }
}

/* ONE */

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}
.minimizeTrigger{
    margin-bottom: 16px;
}