@mixin mobileUp {
  @media (min-width: 590px) {
    @content;
  }
}

.pageContentWrapper{
  flex: 100% 0;
  margin-left: auto;
  height: calc(100vh - 40px);
  background-color: #fff;
  padding: 0;
  transition: all 100ms ease;
  flex: calc(100% - 300px) 0;
  @include mobileUp{
    padding: 20px;
  }
}