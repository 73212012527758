.ChatBoxContainer{
    // background: rgba(145, 158, 171, 0.08);
    border-radius: 16px;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}
.chat-box-header{
    background-color: #007B55;
    padding: 10px 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    display: flex;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    a{
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
    }
    .close{
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg{
            fill: #fff;
        }
    }
    .name{
        color: #fff
    }
}
.chatbox-form-container{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    background-color: #fff;
    border-top: 1px solid #E7E7E7;
}
.chatbox-form-container-wrapper{
    display: flex;
    gap: 16px;
}
.form-container-wrapper{
    display: flex;
    align-items: center;
}
.rio-rate-button{
    position: absolute;
    bottom: -37px;
    right: 0;

    background: #fff5dd;
    color: #B78103;
    padding: 2px 6px;
    margin: 8px auto 5px auto;
    border-radius: 4px;
    opacity: 0.5;
    transition: opacity 300ms ease;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
}
.rating-container{
    padding: 4px 0;
    .feedback{
        margin-bottom: 8px;
    }
}
.rio-rating-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .label{
        color: #d59b16;
        margin-right: 2px;
    }
}
.chat-dialog{
    background-color: #fff;
    color:#00AB55 ;
    padding: 5px 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    max-width: 90%;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    &.rating-enabled{
        margin-bottom: 40px;
    }

    &.sender{
        background-color: #00AB55;
        box-shadow: 0 1px 3px rgba(0, 171, 85, 0.12), 0 1px 2px rgba(0, 171, 85, 0.24);
        color: #fff;
        margin-left: auto;
        margin-right: 0;
    }
    .message{
        font-size: 16px;
        word-break: break-word;
    }
    .watch{
        margin-top: 10px;
    }
}
.dialog-options{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 10px;
    li{
        margin-left: 10px;
    }
}
.chat-dialog {
    ol{
        list-style: auto;
        padding-left: 40px;
        & > li{
            margin-bottom: 0.5em;
        }
    }
    & > p{
        margin-bottom: 1.25em;
    }
}