.moment-choices{
    list-style: none;
    display: flex;
    align-items: center;
    padding-left: 0;
    svg{
        display: block;
        path{
            transition: color 300ms ease;
        }
    }
    li{
        // margin-left: 4px;
        cursor: pointer;
        &.active,
        &:hover{
            svg path{
                fill: rgb(0, 171, 85);
            }
        }
    }

}
.submit-moment{
    svg{
        color: rgb(0, 171, 85);
    }
}
.energy-input-container{
    display: flex;
    align-items: center;
}
.feeling-emotion{
    font-size: 16px;
    color: #727272;
}
.moment-create-form,
.post-form{
    .mood-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }
}
.mood-option-container{
    position: relative;
}

.moment-create-form{
    .imageEditorContainer{
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.3);
    }
    
    .imageEditorWrapper{
        position: relative;
        // height: 200px;
    }
    .cancelButton{
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #fff;
        &:hover{
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
    }
}

.emotions-list{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: calc(100% - 160px);
    max-width: 510px;
    margin-left: auto;
    button{
        color: #fff;
    }
}
.savedImgWrapper{
    max-height: 200px;
    position: relative;
    img{
        height: 200px;
        object-fit: contain;
    }
    .savedImgButton{
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        position: absolute;
        top: 0;
        right: 0;
        transition: all 300ms ease;
        &:hover{
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
    }
}