
.TodoContainer{
    position: relative;
    display: block;
    padding: 13px 0 4px;
    border-bottom: 1px solid #E7E7E7;
    background-color: #fff;
    transition: all 300ms ease;
    &:hover{
        background-color: rgba(0, 133, 119, 0.1);
    }
    .name{
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;

        color: #637381;
    }
    .TodoMoreOptionsContainer{
        display: flex;
        position: absolute;
        z-index: 2;
        right: 2px;
        top: 10px;
    }
    .actions-container{
        position: relative;
        z-index: 2;
        margin-top: 0;
        display: flex;
        justify-content: flex-end;
        button{
            margin-left: 0;
        }
    }
}

.todo-name{
    padding: 3px 0;
    font-size: 14px;
    word-break: break-word;
    cursor: pointer;
    color: #00AB55;
}

.todoPoints{
    font-size: 14px;
}

.todo-more-trigger{
    margin: 4px 0 0;
    font-weight: 600;
    font-size: 12px;
    color: #637381;
    cursor: pointer;
    &:hover{
        color: #00AB55;
    }
}

.points-label{
    // position: absolute; 
    font-size: 13px;
    color: #00AB55;
    span{
        font-weight: 600;
    }
}

.tags{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: calc(100% - 28px);
    // margin-bottom: 6px;
    li{
        margin-right: 8px;
    }
}
.progress-container{
    padding: 6px 5px 0 0;
}
.tag{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7px;
    height: 22px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    background: rgba(99, 115, 129, 0.16);
    letter-spacing: 0.25px;
    margin-bottom: 6px;
    cursor: help;
    .text{
        line-height: 1;
    }

    &.priority{
        color: #B78103;
        background: rgba(255, 193, 7, 0.16);
    }
    &.type{
        color: #00ab55;
        background: rgba(0, 171, 85, 0.08);
    }
    &.points,
    &.thrillingTask{
        color: #8435c8;
        background: #edeaf4;
    }
    .icon-left{
        margin-right: 5px;
        padding-bottom: 1px;
    }
    .type-icon-container{
        display: flex;
        svg + svg{
            margin-left: 4px;
        }
    }
}