@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
.editProfilePicture{
    // height: 100vh;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}
.editPicture {
    height: 100vh;
    overflow: auto!important;
    display: flex;
    flex-direction: column;
    @include tabletUp{
        justify-content: space-between;
        flex-direction: row;
    }
    .postDescription{
        background-color: #fff;
        flex: 1;
        padding: 20px;
        @include laptopUp{
            max-width: 400px;
        }
    }
    .postDescription{
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        @include laptopUp{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 0;
        }
    }
    // post cards style in modal
    .PostCardsContainer{
        flex: 0 0 420px;
        color: #cfcfcf;
        overflow: auto;
        margin-bottom: 0;
    }
    .commenterName a,
    .commentDate,
    .cardContent,
    .nameAndDateContainer a,
    .semiBold{
        color: #cfcfcf;
    }
    .cardHeader .name{
        color: #aeaeae;
    }
    .commenterName a{
        font-weight: normal;
    }
    // fieldset *,
    // textarea,
    .commentMessageWrapper{
        background-color: transparent;
        border: 1px solid #cfcfcf;
        // color: #cfcfcf;
    }
    .load-more-comments{
        background-color: transparent;
    }
    .commentForm{
        .textArea{
            *{
                border-color: #fff;
                color: #fff;
                &:hover{
                    border: #fff;
                }
            }
        }
        
        .MuiOutlinedInput-root{
            &:hover{
                border: 1px solid #fff;
            }
            &.Mui-focused{
                border: 0;
            }
        }
    }
    .commentListWrapper{
        margin-bottom: 12px;
    }
}
.pictureInputContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 702px;
    margin: 0 auto;
    @include laptopUp{
        flex-direction: row;
        max-width: 100%;
        margin: 0;
    }
}
.changeTimelineInputWrapper,
.changeProfileInputWrapper{
    position: relative;
    overflow: auto;
    border: 2px solid rgba(255,255,255,0.4);
    padding: 20px;
    
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @include laptopUp{
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
    }
}
.dragInstruction{
    margin: 10px 0;
    color: #b2b2b2;
}
.ChangeProfilePicture{
    width: 100%;
    margin-top: 60px;
}
.informationUpdate{
    margin: 20px auto 0;
    max-width: 250px;
}