.calendarWrap{
    position: relative;
    .calendar-selection-wrapper{
        position: absolute;
        background: #fff;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border: 1px solid #ccc;
        z-index: 2;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
}
