@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}
.view-action-container{
    display: flex;
    align-items: center;
    button{
        margin-right: 10px;
    }
}
.CalendarContainer{
    position: relative;
}
.calendar-actions-container{
    position: relative;
    // margin-bottom: 15px;
    @include tabletUp{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
// .owner-detail{
//     margin-bottom: 24px;
//     @include tabletUp{
//         margin-bottom: 0;
//     }
// }
.calendar-actions-wrapper{
    width: 100%;
    padding: 4px 0 12px;
    display: flex;
    flex-direction: column;
    @include mobileUp{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    button{
        font-size: 12px;
    }
}
.move-calendar-date-actions-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    max-width: 222px;
    @include mobileUp{
        margin-top: 0;
    }
}
.owner-detail-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.loading-wrapper{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
}

.loader {
    width: 120px;
    height: 120px;
    border-top: 4px solid #19aa59;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}