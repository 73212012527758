@mixin mobileUp {
  @media (min-width: 590px) {
      @content;
  }
}
.GlobalDashboardContainer{
  .actionButtons{
    display: flex;
  }
  .cellMomentData{
    font-size: 0.875rem;
    margin-bottom: 20px;
  }
  .WeeklyGraphSliderContainer{
    margin-bottom: 0;
    margin-left: 0;
  }
}
.latestTodoWrapper{
  border-top: 1px solid #e7e7e7;
  @include mobileUp{
    display: flex;
  }
  .ownerAndTodoDetails{
    flex: 0 0 250px;
  }
}
// .momentSetWrapper{
//   margin-bottom: 20px;
// }
.GlobalDashboardMomentWrapper{
  padding: 13px 20px 14px;
}
.todoWrapper{
  margin-bottom: 10px;
}
.pointsInputWrapper{
  padding: 4px 6px;
}
.userDataHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.userDataInfo{
  padding: 12px 20px;
  margin-bottom: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  
  @include mobileUp{
    border: 1px solid #ccc;
    border-radius: 8px;
  }
}
.UsersListWithEmergentIndexContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.UserDataSectionInfoMomentWrapper{
  padding: 13px 0;
  @include mobileUp{
    margin-left: 20px;
  }
}
.nextAndPrev{
  font-size: 12px;
  font-weight: 700;
}
.UsersPostsWithoutPointsContainer{
  position: relative;
  .refreshButton{
    position: fixed;
    width: 40px;
    top: 8px;
    right: 20px;
    z-index: 4;
  }
}