.NotificationItemContainer{
  margin-bottom: 14px;
}
.notificationHeader{
  display: flex;
}
.notifIcon{
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.comment{
    background-color: #39A7FF;
  }
}
.notificationCommentWrapper{
  font-size: 18px;
}
