.FollowersContainer{
  padding: 20px;
}
.profileCardImgContainer{
  width: 150px;
  height: 150px;
  border-radius: 75px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 10px;
}
.userCard{
  max-width: 200px;
  width: 100%;
  text-align: center;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}
.suggestedPeopleName{
  font-weight: 600;
  font-size: 18px;
  color: #637381;
  text-decoration: none;
}
.followLayoutContainer{
  padding: 18px 0;
  border-bottom: 1px solid #e7e7e7;
}