@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}

.TodoListContainer{
    position: relative;
    padding: 16px 20px;
    width: 300px;
    height: calc(100vh - 45px);
    border-right: 1px solid #E7E7E7;
    // margin-top: 45px;
    @include tabletUp{
        height: 100vh;
        margin-top: 0;
    }
}

.todoSelectionArea{
    display: flex;
    align-items: center;
    #todo-category-selection{
        margin: 0;
    }
    button{
        height: 40px;
        margin-left: 12px;
    }
}

.todo-list-wrapper{
    flex: calc(25% - 15px) 0 0;
    background-color: #fff;
    height: calc(100vh - 45px);
    // this is for dashboard
    position: fixed;
    z-index: 4;
    // top: 45px;
    background: #fff;
    transition: all 300ms ease;
    @include tabletUp{
        height: 100vh;
        left: 220px;
        top: 0;
        // left: -238px;
    }
    &.hide{
        left: -300px;
        @include tabletUp{
            left: -80px;
        }
        .todo-list-show-button-trigger{
            right: -16px;
            svg{
                transform: rotate(180deg) translateX(-5px);
            }
            &:hover{
                right: -30px;
                svg{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .todo-list-show-button-trigger{
        position: absolute;
        right: -16px;
        top: 20px;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        min-width: 30px;
        display: flex;
        transition: all 300ms ease;
        svg{
            transition: transform 300ms ease;
            transform: rotate(0) translateX(0);
        }
    }
}

h2.section-heading-uppercase{
    display: flex;
    align-items: center;
    margin: 0 auto 0 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #00AB55;
    svg{
        margin-right: 4px;
    }
}
.todo-heading-container{
    margin-bottom: 0;
    border-bottom: 1px solid #E7E7E7;
    padding: 0 2px 10px;
    transition: all 300ms ease;
    &.active-search-bar{
        padding-bottom: 0;
        border-bottom-color: transparent;
    }
   
    button{
        &:hover{
            background: rgba(0, 171, 85, 0.08);
            path{
                fill: #00AB55;
            }
        }
    }
}
.todo-list-container{
    overflow: auto;
    height: calc(100vh - 125px);
    transition: height 300ms ease;
    &.is-search-open{
        height: calc(100vh - 154px);
    }
}
.custom-style-pagination{
    ul{
        width: 100%;
        justify-content: space-between;
        li{
            &:first-child{
                margin-right: auto;
            }
            &:last-child{
                margin-left: auto;
            }
        }
    }
}