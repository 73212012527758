@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}

.line-and-bar-graph{
    padding-bottom: 20px;
    min-width: 300px;
    @include laptopUp{
        width: calc(100% - 350px);
    }
    .dataLegends{
        list-style: none;
    }
    .legend-container{
        display: flex;
        align-items: center;
        margin: 0 10px 0 0;
    }
    .graphContainer{
        display: flex;
        align-items: flex-start;
        width: calc(100vw - 54px);
        overflow: auto;
        // margin-left: -16px;
        // border-left: 1px solid #e2e2e2;
        // border-right: 1px solid #e2e2e2;
        @include tabletUp{
            width: auto;
            overflow: visible;
        }
        &.has-side-text{
            padding-left: 15px;
        }
        svg{
            font-size: 10px;
            @include mobileUp{
                font-size: 12px;
            }
            // @include laptopUp{
            //     font-size: 14px;
            // }
        }
    }
    .legend{
        width: 16px;
        height: 14px;
        display: block;
        margin-right: 10px;
        font-size: 0;
    }
    .rv-xy-plot__series--line{
        fill: transparent;
    }
    .label{
        display: block;
        font-size: 12px;
    }
}
.dataLegends {
    padding: 20px 0 10px;
    margin: 0;
    width: 100%;
    max-width: 490px;
    height: 66px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
}