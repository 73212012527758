@mixin mobileUp {
  @media (min-width: 590px) {
      @content;
  }
}
.seminarTitle{
  color: #00ab55;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 5px;
}
.seminarJoiners{
  font-size: 12px;
  margin-bottom: 8px;
  transition: color 300ms ease;
  &:hover{
    cursor: pointer;
    color: #00ab55;
  }
}
.seminarSpeaker{
  font-size: 14px;
  margin-bottom: 8px;
}
.registrationFee{
  font-size: 14px;
  margin-top: 8px;
  color: #00ab55;
  .price{
    font-weight: 700;
  }
}
.infoLabelClassroom{
  font-size: 12px;
}
.dateAndTimeClassroom{
  font-weight: 600;
  font-size: 14px;
  color: #222;
}
.seminarItem{
  padding-bottom: 32px;
}
// .ClassroomContainer{
//   .postList{
//     @include mobileUp {
//       padding-left: 24px;
//     }
//   }
// }

.AllSeminarsContainer{
}
.seminarListContainer{
  
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  .seminarItem{
    width: 200px;
  }
}