@mixin wideUp {
    @media (min-width: 1730px) {
        @content;
    }
}
@mixin desktopUp {
    @media (min-width: 1300px) {
        @content;
    }
}
@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
@mixin mobileLandscapeUp {
    @media (min-width: 950px) {
        @content;
    }
}
@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}

.DashboardContainer{
    position: relative;
    background-color: #fff;
    display: block;
    transition: width 300ms ease;
    // overflow: auto;
    width: calc(100% - 90px);
    width: 100%;
    margin-left: auto;
    // &.show-task-list{
    //     @include laptopUp {
    //         width: calc(100% - 300px);
    //     }
    // }
    .tableCellDataWrapper{
        display: flex;
        align-items: flex-start;
        .moment-type{
            margin-right: 8px;
        }
    }
    h2{
        margin: 0 auto 0 0;
        &.sectionTitle{
            color: #222;
            font-size: 24px;
            margin: 24px 0;
        }
    }
    .TodoContainer{
        padding: 0;
    }
}
.heading-container{
    h5{
        margin: 0 0 20px;
        font-size: 16px;
        color: #008577;
    }
}

.rv-xy-plot__grid-lines__line {
    stroke: rgb(219, 219, 219);
    stroke-width: 1;
}

.dashboard-actions-container{
    position: relative;
    margin-bottom: 15px;
    @include tabletUp{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.top-panel{
    @include wideUp{
        display: flex;
        flex-wrap: wrap;
    }
}
.WeeklyGraphSliderContainer{
    // margin: 0 auto 40px;
    // margin-bottom: 40px;
    width: calc(100vw - 60px);
    // width: 800px;
    // @include mobileUp {
    //     width: 700px;
    // }
    @include tabletUp {
        width: 500px;
    }
    @include laptopUp{
        width: 800px;
    }
}
.mobile-horizontal-slider{
    width: 100%;
    @include laptopUp{
        width: auto;
    }
}
.bottom-panel{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    @include desktopUp{
        justify-content: flex-start;
    }
    .burnout-index-table-data-container{
        width: 100%;
        margin-bottom: 20px;
        @include laptopUp{
            width: calc(45% - 10px);
            margin-bottom: 0;
        }
        @include desktopUp{
            width: 450px; 
            margin: 0 20px 0 0;
        }
    }
    .productivity-data-container{
        padding: 20px;
        width: 100%;
        @include laptopUp{
            width: calc(55% - 10px);
        }
        @include desktopUp{
            width: 450px; 
        }
        // margin-right: 20px;
    }
}

.doughnutChartWithTableSectionContainer {
    .rv-xy-plot__series--label-text, 
    .workspaceBugList .sprintWorkspaceActiveBugContainer .rv-xy-plot__series--label-text {
        stroke: #fff;
        color: #fff;
        fill: #fff;
    }
    .custom-data-legends{
        list-style: none;
        padding: 20px 0 0;
        width: 310px;
        @include mobileUp{
            padding-top: 0;
        }
        .legend-data{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            margin-left: 0;
        }
        .labelColor{
            display: block;
            height: 20px;
            flex: 0 0 20px;
            border-radius: 100%;
            font-size: 0;
            margin-right: 10px;
        }
    }
    .dataGraphContainer{
        & > div{
            margin: 0 auto;
        }
    }
    .legend-data{
        font-size: 1rem;
        transition: color 300ms ease;
        cursor: pointer;
        &.active,
        &:hover{
            color: #00AB55;
        }
    }
    .tableDataContainer{
        width: 100%;
        max-width: 450px;
    }
    .graph-and-table-data-container{
        @include mobileLandscapeUp{
            display: flex;
        }
    }
    .dataGraphContainer{
        padding-right: 24px;
        display: flex;
    }
}
.doughnut-chart-wrapper{
    padding: 20px;
}
.todo-types-and-status-info-wrapper{
    width: 100%;
    @include laptopUp{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
.todo-status-chart,
.todo-types-chart{
    margin-bottom: 20px;
    @include laptopUp{
        width: calc(50% - 10px);
    }
}
.dashboardWrapper{
    flex: 100% 0;
    margin-left: auto;
    // height: calc(100vh - 40px);
    background-color: #fff;
    padding: 20px;
    transition: all 100ms ease;
    flex: calc(100% - 300px) 0;
}
.data-graph-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // margin-right: 30px;
}
// .idea-content-wrapper{
//     tr{
//         display: flex;
//         flex-direction: column;
//         vertical-align: top;
//         border: 1px solid rgba(224, 224, 224, 1);
//         margin-bottom: 20px;
//         @include desktopUp{
//             display: table-row;
//             border: 0;
//             margin-bottom: 0;
//         }
//         td{
//             border-bottom: 0;
//             display: block;
//             @include desktopUp{
//                 display: table-cell;
//                 border-bottom: 1px solid rgba(224, 224, 224, 1);
//             }
//         }
//     }
//     td {
//         padding: 16px;
//         &.noData{
//             display: none;
//             @include desktopUp{
//                 display: table-cell;
//             }
//         }
//     }
//     thead{
//         display: none;
//         @include desktopUp{
//             display: table-header-group;
//         }
//     }
//     .cellMomentList{
//         .cellMomentData{
//             margin-bottom: 20px;
//             text-align: left;
//             &:last-child{
//                 margin-bottom: 0;
//             }
//         }
//     }
//     .momentListContainer{
//         flex-grow: 1;
//         max-height: 410px;
//         overflow: auto;
//     }
//     .weekly-average-data-container{
//         max-width: 300px;
//         margin-right: 24px;
//     }
//     .data-graph-container{
//         .ideaDataComparisonWrapper{
//             margin-right: 20px;
//             margin-bottom: 20px;
//             &:last-child{
//                 margin-right: 0;
//             }
//         }
//     }
// }

.cellHeading{
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
    color: #222;
    font-weight: bold;
    margin-bottom: 16px;
    display: block;
    @include desktopUp{
        display: none;
    }
}
.TaskMomentsTableContainer{
    
    @include desktopUp{
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    }
}
.emergentIndexDataWrapper{
    @include laptopUp{
        display: flex;
    }
}
.tabStyle{
    margin-bottom: 16px;
    margin-right: auto;
    border-bottom: 1px solid #ccc;
}
.eITabWrapper{
    @include laptopUp{
        padding-left: 350px;
    }
}