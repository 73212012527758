@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
.NotesContainer{
    position: relative;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
}
.note-content{
    height: 300px;
    // border: 1px solid #ccc;
    // border-radius: 4px;
    padding: 20px 0 20px 20px;
    overflow: auto;
}
.NoteEditorContainer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
    
    .ck-editor__main{
        .ck-content{
            height: calc(100% - 96px);
        }
    }
    .ck.ck-editor{
        margin-bottom: 12px;
        height: calc(100% - 64px);
        .ck-content{
            width: 100%;
            height: 100%;
        }
        .ck-editor__main{
            height: calc(100% - 39px);
        }
    }
}

.todoInNoteHeading {
    padding: 12px 0 0;
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #5b5b5b;
    margin-bottom: 12px;
    padding: 0;
}

.todosInNotesList{
    position: relative;
    .todoInNote{
        font-size: 14px;
        word-break: break-word;
        color: #00AB55;
        margin-bottom: 16px;
        cursor: pointer;
        background-color: #fff;
        transition: all 300ms ease;
        &:hover{
            background-color: rgba(0, 133, 119, 0.1);
        }
    }
}
.note-content{
    .content-action-wrapper{
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }
    .JSONToReactContainer{
        p,li{
            position: relative;
            transition: all 300ms ease;
            background-color: #fff;
            padding-right: 40px;
            &:hover{
                background-color: rgb(227, 255, 221);
                .content-action-wrapper{
                    opacity: 1;
                }
            }
        }
    }
}
.note-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(244, 246, 248);
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    padding: 10px 15px;
    h2{
        font-size: 1rem;
        color: rgb(33, 43, 54);
        margin: 0;
    }
}
.color-selection{
    width: 24px;
    padding: 5px!important;
    svg{
        display: block;
        width: 100%;
        height: auto;
    }
}
.note-list-wrapper{
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: calc(100vh - 200px);
    padding: 0 2px;
    .NotesContainer{
        width: 100%;
        margin: 0 0 20px 0;
        @include tabletUp {
            width: calc(50% - 10px);
            margin: 0 0 20px 0;
        }
        &:nth-child(2n+1){
            margin-right: auto;
        }
    }
}
.NotesAsideContainer{
    .aside-data{
        // margin-bottom: 25px;
        padding-bottom: 16px;
        // height: 34px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E7E7E7;
    }
    .MuiSelect-select{
        width: 100%;
    }
}
.note-action-wrapper{
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        margin-right: 12px;
        width: 92px;
        &:last-child{
            margin-right: 0;
        }
    }
}
.color-container{
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 16px;
    width: 100%;
    border: 1px solid #d3d3d3;
    padding: 5px 8px;
    border-radius: 4px;
    padding: 16px 12px;
    &.small{
        padding: 6px 12px;
    }
    p{
        margin-right: 10px;
    }
    .selection-wrapper{
        margin-left: auto;
    }
}