.gridItemLayout{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  .TodoContainer{
    width: 240px;
    border: 1px solid #b5bcc3;
    padding: 12px;
    border-radius: 8px;
  }
}