@mixin wideUp {
    @media (min-width: 1730px) {
        @content;
    }
}
@mixin desktopUp {
    @media (min-width: 1300px) {
        @content;
    }
}
@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}
.planning-wrapper{
    display: flex;
    justify-content: space-between;
}
.PlanningContainer{
    padding: 0 20px;
    .calendar-layout{
        // width: calc(100% - 500px);
        width: 100%;
        @include desktopUp{
            flex: calc(100% - 500px) 0 0;
        }
        @include wideUp{
            flex: calc(100% - 700px) 0 0;
        }
    }
    .header-container{
        border-bottom: 1px solid #e5e5e5;
    }
    .header-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        h1{
            margin-bottom: 0;
        }
    }
    .dropdown-actions{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 24px;
        width: 100%;
        @include mobileUp{
            margin-top: 0;
            width: auto;
        }
    }
    .actions-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 16px 0 0 14px;
        width: 100%;
        @include mobileUp{
            flex-direction: row;
            justify-content: flex-end;
        }
        @include tabletUp{
            width: calc(100vw - 270px);
        }
        @include desktopUp{
            margin-top: 0;
            max-width: 322px;
            width: 100%;
        }
        button{
            margin-left: 0;
            height: 40px;
            line-height: 1.2;
            @include mobileUp{
                width: calc(33.3333% - 10px);
                margin-left: 14px;
            }
            @include tabletUp{
                width: auto;
            }
            
            &:first-child{
                margin-left: 0;
            }
        }
    }
    .PlanListSelectionContainer{
        margin-left: 14px;
        width: 177px;
    }
    #selected-plan{
        display: flex;
    }
    .button-wrapper{
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-left: auto;
        @include mobileUp{
            max-width: 322px;
            margin-left: 14px;
        }
        button{
            width: 50%;
            @include mobileUp{
                width: auto;
            }
        }
    }
}
.header-container{
    padding: 20px 0;
    @include laptopUp{
        display: flex;
        align-items: center;
    }
    h1{
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        color: #00AB55;
        margin: 0 0 20px;
        @include laptopUp{
            margin-bottom: 0;
        }
    }
    h2{
        margin-bottom: 0;
    }
    
}
.planning-wrapper{
    .tui-full-calendar-popup-edit{
        display: none;
    }
    .tui-full-calendar-popup-container{
        .tui-full-calendar-popup-vertical-line{
            display: none;
        }
        .tui-full-calendar-popup-delete{
            width: 100%;
        }
    }
}