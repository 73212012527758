.LinkedTasksContainer{
  margin-bottom: 20px;
  .LinkedTasksContainer{
    padding-left: 20px;
    border-left: 1px solid #c4c4c4;
  }
}
.idea-content-wrapper{
  & > .LinkedTasksContainer{
    margin-bottom: 16px;
  }
}
.taskNameWrapper{
  display: flex;
}
.moreLinkedTodos{
  font-size: 10px;
  align-self: flex-end;
  padding-bottom: 4px;
  margin-left: 6px;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover{
    color: #00AB55;
    font-weight: 600;
  }
}