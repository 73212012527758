.MotivationsContainer{
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0 8px 8px;
  margin: 8px 0;
  .notificationsList{
    max-height: 150px;
    overflow: auto;
  }
}

.motivationHeading{
  font-size: 24px;
  margin-bottom: 8px;
}