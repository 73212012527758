.userCardChip{
  display: flex;
  align-items: center;
  padding: 0;
  // border-bottom: 1px solid #ccc;
  .suggestedPeopleName{
    font-size: 15px;
    line-height: 1.2;
  }
}
.userCardChipImgContainer{
  width: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
  border: 1px solid #ccc;
}
.nameAndDateContainer{
  a{
    color: #212B36;
    font-weight: 600;
    text-decoration: none;
  }
}