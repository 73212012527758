@mixin tabletUp {
  @media (min-width: 768px) {
      @content;
  }
}
.MotivationalQoutesContainer{
  padding: 20px;
  display: flex;
  gap: 16px;
  .motivation{
    margin-bottom: 12px;
    font-size: 16px;
  }
}
.OpenAIChatboxContainer{
  width: 400px;
  height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  @include tabletUp{
    height: calc(100vh - 40px);
  }
}
.OpenAIChatbox-form-container{
  margin-top: auto;
}
.motivation{
  position: relative;
  padding-right: 60px;

}
.qouteActions{
  position: absolute;
  top: 0;
  right: 0;
}