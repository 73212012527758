.emergentIndexDetails{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
}
.clickableDetail{
  cursor: pointer;
}
.UserCardMoreOptionsContainer{
  position: absolute;
  top: 10px;
  right: 10px;
}
.userCard{
  position: relative;
}