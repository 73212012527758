@mixin tabletUp {
    @media (min-width: 768px) {
        @content;
    }
}
.GoalsContainer{
    width: 100%;
}
.note-list-wrapper{
    .GoalsContainer{
        .NotesContainer{
            margin-right: 0;
            margin-left: 0;
            @include tabletUp {
                width: 100%;
            }
        }
    }
}