@mixin wideUp {
    @media (min-width: 1730px) {
        @content;
    }
}
@mixin desktopUpPlus {
    @media (min-width: 1400px) {
        @content;
    }
}
@mixin desktopUp {
    @media (min-width: 1300px) {
        @content;
    }
}
@mixin laptopUp {
    @media (min-width: 1100px) {
        @content;
    }
}
@mixin mobileUp {
    @media (min-width: 590px) {
        @content;
    }
}

.AuthContainer{
    @include laptopUp{
        display: flex;
        align-items: stretch;
        justify-content: center;
    }
}

.login-form.form-container{
    margin-bottom: 40px;
}
.login-page-info-wrapper,
.auth-form-wrapper-container{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include laptopUp{
        width: 50%;
        min-height: 100vh;
    }
}
.auth-form-wrapper-container{
    background-image: url('../../Assets/login-form-bg.svg');
    padding: 80px 20px;
    min-height: 100vh;
    @include mobileUp{
        padding: 80px 40px;
    }
    @include desktopUpPlus{
        padding-top: 10%;
    }
}
.login-page-info-wrapper{
    position: relative;
    background-image: url('../../Assets/login-elements-bg.svg');
    padding: 40px 20px;
    @include mobileUp{
        padding: 80px 40px;
        padding: 60px;
    }
    @include desktopUpPlus{
        padding-top: 13%;
    }
}

// .form-container{
//     border-radius: 8px;
//     padding: 20px;
//     box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
// }
.form-wrapper{
    text-align: center;
    .title{
        font-weight: 700;
        font-size: 42px;
        color: #00AB55;
        text-align: left;
        margin: 0 0 20px 0;
    }
}
.logo-container{
    margin: 0 auto;
    max-width: 457px;
    width: 100%;
    padding: 20px;
    svg{
        display: block;
        width: 100%;
        height: auto;
    }
}
.info-section-1,
.info-section-2{
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.login-page-info-container{
    position: relative;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    @include laptopUp{
        max-width: 711px;
        margin: 0 auto 0 0;
    }
    @include desktopUpPlus{
        padding-top: 270px;
    }
}
.info-section-1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 260px;
    height: 260px;
    padding: 40px;
    margin: 0 auto 40px;
    @include desktopUpPlus{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        margin: 0;
        max-width: 320px;
        height: 320px;
    }
    .app-name{
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
        text-transform: uppercase;
        color: #00AB55;
    }
    .logo-wrapper{
        max-width: 80px;
        width: 100%;
        margin-bottom: 30px;
        @include desktopUpPlus{
            max-width: 90px;
        }
        svg{
            width: 100%;
            display: block;
            height: auto;
        }
    }
}
.info-section-2{
    width: 100%;
    padding: 40px 40px 50px;
    @include desktopUpPlus{
        max-width: 473px;
    }
    .info-text{
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: #637381;
        margin-bottom: 18px;
    }
    .nav-link{
        height: auto;
        color: #00AB55;
    }
}
.features-info-list{
    font-weight: 700;
    font-size: 36px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #00AB55;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 4px solid #cdedd7;
    margin-bottom: 20px;
    @include mobileUp{
        font-size: 52px;
        line-height: 1.3;
    }
}