.PrivacyPolicyContainer,
.TermsOfServiceContainer{
  background-size: 133%;
  background-repeat: repeat-y;
  background-position: center;
}
.textWrapper{
  padding: 40px;
  max-width: 1200px;
  dl{
    dt{
      font-weight: 700;
    }
    dd{
      margin-bottom: 20px;
      padding-left: 40px;
    }
  }
  h1{
    font-size: 36px;
    margin-bottom: 30px;
  }
  p{
    margin-bottom: 20px;
  }
}
