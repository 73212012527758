.tradingStatistics{
  padding: 0 16px;
  max-width: 600px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 8px;
  .statisticsRow{
    display: flex;
  }
  .data{
    width: 50%;
  }
  .label{
    color: #6c757d;
    font-weight: 700;
  }
  .statisticsRow{
    padding: 16px 0;
    border-bottom: 1px solid #ccc;
    &:last-child{
      border-bottom: 0;
    }
  }
}