.groupPageOptions{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 600px;
  margin: 0 auto;
  p{
    cursor: pointer;
    transition: all 300ms ease;
    &:hover{
      color: #00AB55;
    }
  }
}
.groupManagementTable{
  .nav-link{
    height: auto;
  }
  svg{
    width: 100%;
    height: auto;
  }
}
.UserSelectionItemContainer{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .actions-wrapper{
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
.userListContainer{
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.userDataLinks{
  display: flex;
  justify-content: flex-start;
  a{
    padding: 0 6px;
    height: auto;
    display: flex;
    align-items: center;
    margin: 0;
  }
}
.iconHeading{
  width: 28px;
  display: block;
  margin: 0 auto;
}
.defaultSelectedGroup{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 2px 0 6px;
  transition: all 300ms ease;
  &.defaultSelectedGroup,
  &:hover{
    color: #00ab55;
  }
}
.timelineButtonActions{
  position: absolute;
	right: 20px;
	bottom: 20px;
	display: flex;
  flex-direction: column;
}
.classList{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}